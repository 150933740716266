import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styled from '@emotion/styled'
import { Container, Heading } from 'theme-ui'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'

const LegalPageTemplate = ({ data }) => {
  const { page } = data
  const metaDescription = page.metadata?.description?.description
  const content = page.sections[0]
  const copyText = content && content.body ? content.body.body : null
  return (
    <Page>
      <Metadata
        title={page.name}
        description={metaDescription}
      />
      <Wrapper>
        <Container variant="small">
          <Heading as="h1" variant="secondaryMedium">
            {page.name}
          </Heading>
          {copyText && (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {copyText}
            </ReactMarkdown>
          )}
        </Container>
      </Wrapper>
    </Page>
  )
}

export default LegalPageTemplate

export const pageQuery = graphql`
  query LegalPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      sections {
        body {
          body
        }
      }
      metadata {
        description {
          description
        }
      }
      name
    }
  }
`

const Wrapper = styled.section`
  padding: 48px 0;

  p,
  ul,
  ol,
  table {
    margin-top: 24px;
  }

  ul {
    margin-left: 16px;
  }

  a {
    color: #0076cb;
    text-decoration: underline;
  }

  table {
    border: 1px solid #c7c7c7;
    border-spacing: 0;
  }

  th,
  td {
    border-bottom: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    padding: 8px;
    text-align: left;

    &:last-of-type {
      border-right: none;
    }
  }

  tr:last-of-type td {
    border-bottom: none;
  }

  @media screen and (min-width: 992px) {
    padding: 64px 0;
  }
`

LegalPageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      name: PropTypes.string,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          body: PropTypes.shape({
            body: PropTypes.string
          })
        })
      )
    })
  })
}
